import { useFieldState } from "informed";
import React from "react";
import Button from "~/components/Button";
import TextInput from "~/components/TextInput";
import { H3 } from "~/components/Typography";
import cleanNumberInput from "~/helpers/formatters/cleanNumberInput";
import styles from "./index.module.scss";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import hasErrorCode from "~/helpers/hasErrorCode";
import Checkbox from "~/components/Checkbox";

enum Field {
  PolicyId = "AdminUpdatePolicyPremium-PolicyId",
  AnnualPremium = "AdminUpdatePolicyPremium-AnnualPremium",
  BccEmail = "AdminUpdatePolicyPremium-BccEmail",
  Passcode = "AdminUpdatePolicyPremium-Passcode",
}

const UPDATE_POLICY_PREMIUM = gql`
  mutation adminUpdatePolicyPremium(
    $policyId: String!
    $annualPremium: Float!
    $sendBccEmail: Boolean!
    $passcode: String!
  ) {
    adminUpdatePolicyPremium(
      policyId: $policyId
      annualPremium: $annualPremium
      sendBccEmail: $sendBccEmail
      passcode: $passcode
    ) {
      refundAmount
      singleChargeAmount
      newMonthlyAmount
      remainingPaymentCount
    }
  }
`;

type Props = {
  onSuccess: (successMessage?: string) => void;
  onError: (errorMessage: string | undefined, errorCode?: string) => void;
};

const UpdatePolicyPremium = ({ onSuccess, onError }: Props) => {
  const policyIdState = useFieldState(Field.PolicyId);
  const annualPremiumState = useFieldState(Field.AnnualPremium);
  const bccEmailState = useFieldState(Field.BccEmail);
  const passcodeState = useFieldState(Field.Passcode);
  const [processing, setProcessing] = React.useState<boolean>(false);
  const [updatePolicyPremium] = useMutation(UPDATE_POLICY_PREMIUM);

  const handleSubmit = async () => {
    setProcessing(true);
    onSuccess(undefined);
    onError(undefined);

    try {
      const response = await updatePolicyPremium({
        variables: {
          policyId: String(policyIdState.value),
          annualPremium: cleanNumberInput(annualPremiumState.value, true),
          sendBccEmail: !!bccEmailState.value,
          passcode: String(passcodeState.value),
        },
      });

      setProcessing(false);

      const data = response?.data?.adminUpdatePolicyPremium;

      if (data.refundAmount) {
        onSuccess(`Please issue user a refund for $${data.refundAmount}.`);
      } else if (data.singleChargeAmount) {
        onSuccess(
          `User has been charged the amount of $${data.singleChargeAmount}.`
        );
      } else if (data.newMonthlyAmount) {
        onSuccess(
          `User's monthly payment has been updated to $${data.newMonthlyAmount}.`
        );
      } else {
        onSuccess("Operation Successful!");
      }
    } catch (error) {
      onError(
        error.message,
        hasErrorCode(error, "NOT_ADMIN") ? "NOT_ADMIN" : undefined
      );

      setProcessing(false);
    }
  };

  return (
    <div className={styles.Content}>
      <H3>Update Policy Premium</H3>

      <TextInput
        type="text"
        label="Policy ID"
        placeholder="AL-000000-AAAAAA"
        field={Field.PolicyId}
      />

      <TextInput
        type="text"
        label="New Annual Premium"
        placeholder="0.00"
        field={Field.AnnualPremium}
        inputMode="decimal"
      />

      <Checkbox
        className={styles.SmallMarginTop}
        field={Field.BccEmail}
        label="Send Me A Copy of The Change Email"
        initialValue
      />

      <TextInput
        type="text"
        label="Passcode"
        placeholder="Passcode"
        field={Field.Passcode}
      />

      <Button
        disabled={
          !policyIdState.value ||
          !annualPremiumState.value ||
          !passcodeState.value ||
          processing
        }
        onClick={handleSubmit}
      >
        {processing ? "Processing..." : "Process"}
      </Button>
    </div>
  );
};

export default UpdatePolicyPremium;
