import React from "react";
import DownloadImg from "~/assets/images/download.svg";
import DownloadImgAlt from "~/assets/images/download flow.svg";
import styles from "./index.module.scss";

type PropsType = {
  name: string;
  url: string;
  alt?: boolean;
  hideIcon?: boolean;
};

const DownloadLink = ({ name, url, alt, hideIcon }: PropsType) => {
  return (
    <a
      href={url}
      download
      target="_blank"
      rel="noopener noreferrer"
      className={styles.Link}
    >
      {hideIcon ? (
        <>{name}</>
      ) : (
        <>
          {alt ? (
            <>
              <img
                src={DownloadImgAlt}
                alt="download icon"
                className={styles.IconAlt}
                aria-hidden
              />
              {name}
            </>
          ) : (
            <>
              {name}
              <img
                src={DownloadImg}
                alt="download icon"
                className={styles.Icon}
                aria-hidden
              />
            </>
          )}
        </>
      )}
    </a>
  );
};

export default DownloadLink;
