import React from "react";
import CheckImage from "~/assets/images/check.png";
import { H4, TextSmall } from "~/components/Typography";
import HomeImage from "~/assets/images/home.png";
import CarImage from "~/assets/images/car-large.png";
import PortalList from "~/components/PortalList";
import { formatCurrency } from "~/helpers/currency";

import styles from "./index.module.scss";
import { formatIsoDate } from "~/helpers/dates";
import moment from "moment";

type PropsType = {
  annualPremium: number;
  monthlyPremium: number;
  nextPaymentDate?: string;
  finalPaymentDate?: string;
  policyObject: "auto" | "home";
};

const MAGIC_NUMBERS = {
  ten: 10,
};

const PortalPaymentCard = ({
  annualPremium,
  monthlyPremium,
  nextPaymentDate,
  finalPaymentDate,
  policyObject,
}: PropsType) => {
  const datesCollection = [];

  if (nextPaymentDate) {
    const date = formatIsoDate(nextPaymentDate, "MMM dd, yyyy");
    for (let i = 0; i < MAGIC_NUMBERS.ten; i++) {
      const newDate = moment(date).add(i, "month").format("MMM DD, YYYY");
      datesCollection.push(newDate);
    }
  }

  const dataList = [
    {
      title: "Monthly payment",
      value: formatCurrency(monthlyPremium, false),
    },
  ];
  if (nextPaymentDate) {
    dataList.push({
      title: "Next payment due",
      value: formatIsoDate(nextPaymentDate, "MMM dd, yyyy"),
    });
  }
  if (nextPaymentDate) {
    for (let i = 1; i < MAGIC_NUMBERS.ten; ++i) {
      dataList.push({
        title: "",
        value: datesCollection[i],
      });
    }
  }
  dataList.push({
    title: "Final payment",
    value: finalPaymentDate
      ? formatIsoDate(finalPaymentDate, "MMM dd, yyyy")
      : "N/A",
  });

  return (
    <div
      className={styles.Payment}
      role="group"
      aria-label="payment information"
    >
      <div className={styles.PaymentIcon}>
        <img
          src={CheckImage}
          alt="check mark"
          aria-hidden
          className={styles.CheckImage}
        />
      </div>
      <div className={styles.PaymentHeader}>
        <TextSmall>Annual Premium</TextSmall>
        <H4>{formatCurrency(annualPremium, false)}</H4>
      </div>
      <div className={styles.PaymentDetails}>
        <PortalList data={dataList} />
      </div>

      <div className={styles.PaymentImageContainer}>
        {policyObject === "auto" ? (
          <img
            src={CarImage}
            alt="car in front of palm trees"
            className={styles.PaymentImage}
          />
        ) : undefined}

        {policyObject === "home" ? (
          <img
            src={HomeImage}
            alt="home surrounded by palm trees"
            className={styles.PaymentImage}
          />
        ) : undefined}
      </div>
    </div>
  );
};

export default PortalPaymentCard;
