import React, { useEffect, useState, useLayoutEffect } from "react";

import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { useLocation, useHistory } from "react-router-dom";
import creditCardType from "credit-card-type";

import styles from "./index.module.scss";
import { H3, Text, Bold, TextSmall } from "~/components/Typography";
import SubmitButton from "~/components/SubmitButton";
import { useUserContext } from "~/contexts/UserProvider";
import { formatCurrency } from "~/helpers/currency";
import checked from "~/assets/images/checked.png";
import GridWrapper from "~/components/GridWrapper";
import LoadingWrapper from "~/components/LoadingWrapper";
import { HeapEventName, useHeapContext } from "~/contexts/HeapProvider";
import AppError from "~/helpers/AppError";
import pushWithParams from "~/helpers/pushWithParams";
import Modal from "~/components/Modal";

export type PaymentResultResponse = {
  success: boolean;
  transactionStatus?: string;
  cvv2Result?: string;
  reason?: string;
  reasonCode?: string;
  responseCode?: string;
  finalCardNumbers?: string;
  currency?: string;
  purchaseAmount?: string;
  resultTime?: string;
  authCode?: string;
  errMessage?: string;
  tokenizedPAN?: string;
};

const CHECK_PAYMENT_TRANSACTION_RESULT = gql`
  mutation checkPaymentTransactionResult($securityToken: String!) {
    checkPaymentTransactionResult(securityToken: $securityToken) {
      success
      transactionStatus
      cvv2Result
      reason
      reasonCode
      responseCode
      finalCardNumbers
      currency
      purchaseAmount
      resultTime
      authCode
      errMessage
      tokenizedPAN
    }
  }
`;

const SUBMIT_POLICY = gql`
  mutation SubmitPolicy($submitPolicyInput: String!) {
    submitPolicy(input: $submitPolicyInput)
  }
`;

const GET_POLICY = gql`
  query($getGeneralPolicyInput: String!) {
    getGeneralPolicy(input: $getGeneralPolicyInput) {
      isPaymentRecurring
      quote {
        totalValue
      }
    }
  }
`;

type Props = {
  didNotAgreeRedirect: string;
  nextPath?: string;
  isPaymentUpdate?: boolean;
  onNext?: () => void;
};

export default function PaymentResult({
  didNotAgreeRedirect,
  isPaymentUpdate,
  nextPath,
}: Props) {
  const userCtx = useUserContext();
  const history = useHistory();
  const heapCtx = useHeapContext();

  const [checkPaymentTransaction, { data, error, loading }] = useMutation(
    CHECK_PAYMENT_TRANSACTION_RESULT
  );
  const [securityToken, setSecurityToken] = useState("");
  const [policyId, setPolicyId] = useState("");

  const [success, setSuccess] = useState(false);
  const [policySubmitted, setPolicySubmitted] = useState(false);
  const [paymentResult, setPaymentResult] = useState<PaymentResultResponse>();

  const [appError, setAppError] = useState<AppError>();

  type SubmitPolicyResponse = {
    submitPolicy: string;
  };

  const { data: policyData, loading: policyLoading } = useQuery(GET_POLICY, {
    variables: {
      getGeneralPolicyInput: policyId,
    },
    skip: !policyId,
  });

  const isRecurring = policyData?.getGeneralPolicy?.isPaymentRecurring;

  const querySearch = new URLSearchParams(useLocation().search);

  const firstRecurringInstallment = parseFloat(
    paymentResult?.purchaseAmount as string
  );

  const handleError = React.useCallback(
    (error: { title: string; subtitle: string }) => {
      let errorRedirect: string;

      if (policyId !== "") {
        heapCtx.track(HeapEventName.ONBOARDING_PAYMENT_INFORMATION, {
          "Policy ID": policyId,
        });

        heapCtx.track(HeapEventName.ONBOARDING_PAYMENT_ERROR_SCREEN, {
          "Policy ID": policyId,
        });
      }

      if (error?.subtitle?.includes("User did not agree to payment terms.")) {
        errorRedirect = didNotAgreeRedirect;
      }

      setAppError(
        new AppError(error.subtitle, {
          mainButton: {
            text: "Go back to payment",
            onClick: () =>
              errorRedirect ? history.push(errorRedirect) : history.goBack(),
          },
        })
      );
    },
    [heapCtx, history, policyId, didNotAgreeRedirect]
  );

  const [submitPolicy, submitPolicyResult] = useMutation<SubmitPolicyResponse>(
    SUBMIT_POLICY,
    {
      onError: () => {
        throw new AppError(
          "There was an error creating your new policy. Please go back and try again."
        );
      },
    }
  );

  useLayoutEffect(() => {
    if (appError) {
      throw appError;
    }
  }, [appError]);

  useEffect(() => {
    setSecurityToken(querySearch.get("ID") || "");
    setPolicyId(querySearch.get("policy") || "");
  }, [querySearch]);

  useEffect(() => {
    if (securityToken.length) {
      checkPaymentTransaction({
        variables: {
          securityToken,
        },
      });
    }
  }, [securityToken, checkPaymentTransaction]);

  useEffect(() => {
    window.parent.postMessage(
      { messageType: "result-loaded" },
      window.location.origin
    );
  }, []);

  useEffect(() => {
    if (data && data.checkPaymentTransactionResult) {
      if (data.checkPaymentTransactionResult.success) {
        setPaymentResult(data.checkPaymentTransactionResult);
        setSuccess(true);
        if (!isPaymentUpdate && !policySubmitted) {
          submitPolicy({
            variables: { submitPolicyInput: policyId },
          });
          setPolicySubmitted(true);
        }
      } else {
        const {
          reason,
          reasonCode,
          errMessage,
        } = data.checkPaymentTransactionResult;

        const error = {
          title: "Uh-oh. Transaction failed.",
          subtitle:
            reasonCode === "3090"
              ? "Our payment provider is currently experiencing technical difficulties. Please try again at a future time."
              : reason ||
                errMessage ||
                "It looks like your payment didn't go through.\nPlease try again or use a different card.",
        };

        handleError(error);
      }
    } else if (error) {
      const errorResult = {
        title: "Uh-oh. Something went wrong.",
        subtitle: error.message,
      };
      handleError(errorResult);
    }
  }, [
    data,
    error,
    handleError,
    policySubmitted,
    policyId,
    submitPolicy,
    isPaymentUpdate,
  ]);

  const onClickNext = () => {
    if (policyId !== "") {
      heapCtx.track(HeapEventName.ONBOARDING_PAYMENT_INFORMATION, {
        "Policy ID": policyId,
      });

      heapCtx.track(HeapEventName.ONBOARDING_PAYMENT_SUCCESS_CONTINUE, {
        "Policy ID": policyId,
      });
    }

    if (isPaymentUpdate) {
      window.location.href = `/portal/policy/${policyId}`;
    } else if (nextPath) {
      pushWithParams(history, nextPath);
    }
  };

  const screenLayout = (
    <>
      {success && !submitPolicyResult.loading && (
        <div className={styles.Payment}>
          {!isPaymentUpdate && (
            <Text className={styles.TopPhrasing}>
              Thank you for your payment, {userCtx.name}.
            </Text>
          )}
          <H3 className={styles.Heading} component="h1">
            {isPaymentUpdate
              ? "Your payment has been successfully updated."
              : "Your payment has been successfully completed."}
          </H3>
          <div className={styles.PaymentResultCard}>
            <div className={styles.CheckedImgWrapper}>
              <img src={checked} alt="checked" className={styles.CheckedImg} />
            </div>
            <div className={styles.DataWrapper}>
              {!isPaymentUpdate && (
                <div className={styles.PaymentResultCardRow}>
                  <Text className={styles.LeftCol} fontWeight="bold">
                    Total Transaction
                  </Text>

                  <Text
                    className={styles.TransactionTotalAmount}
                    fontWeight="bold"
                  >
                    {formatCurrency(firstRecurringInstallment)}
                  </Text>
                </div>
              )}
              <div className={styles.PaymentResultCardRow}>
                <TextSmall className={styles.LeftCol} fontWeight="bold">
                  Card type
                </TextSmall>
                <TextSmall className={styles.RightCol}>
                  {paymentResult?.tokenizedPAN &&
                    creditCardType(paymentResult.tokenizedPAN)[0].niceType}
                </TextSmall>
              </div>
              <div className={styles.PaymentResultCardRow}>
                <TextSmall className={styles.LeftCol} fontWeight="bold">
                  Card number
                </TextSmall>
                <TextSmall className={styles.RightCol}>
                  **** **** **** {paymentResult?.finalCardNumbers}
                </TextSmall>
              </div>
              <div className={styles.PaymentResultCardRow}>
                <TextSmall className={styles.LeftCol} fontWeight="bold">
                  Date / time
                </TextSmall>
                <TextSmall className={styles.RightCol}>
                  {paymentResult?.resultTime}
                </TextSmall>
              </div>
              <div className={styles.PaymentResultCardRow}>
                <TextSmall className={styles.LeftCol} fontWeight="bold">
                  Auth Code
                </TextSmall>
                <TextSmall className={styles.RightCol}>
                  {paymentResult?.authCode}
                </TextSmall>
              </div>
            </div>
          </div>

          {!isPaymentUpdate && isRecurring && (
            <TextSmall className={styles.AutomaticallyCharged}>
              <Bold>
                {formatCurrency(
                  parseFloat(paymentResult?.purchaseAmount as string)
                )}
              </Bold>{" "}
              has now been charged as an instalment. The rest of the equal
              monthly payments will be charged over the next 10 months.
            </TextSmall>
          )}

          <SubmitButton
            id="PaymentResult-SubmitButton"
            onClick={onClickNext}
            className={styles.ContinueBtn}
            autoFocus
          >
            Continue
          </SubmitButton>
        </div>
      )}
    </>
  );

  return (
    <>
      {isPaymentUpdate ? (
        <Modal
          title="Update Payment Information"
          isOpen
          shouldOverlayCloseOnClick
          onClose={onClickNext}
          noOffset
          id="update-result-modal"
        >
          <div className={styles.ContentWrapper}>
            <GridWrapper>
              <LoadingWrapper loading={loading || submitPolicyResult.loading} />
              {screenLayout}
            </GridWrapper>
          </div>
        </Modal>
      ) : (
        <div className={styles.ContentWrapper}>
          <LoadingWrapper
            loading={loading || submitPolicyResult.loading || policyLoading}
          />
          {screenLayout}
        </div>
      )}
    </>
  );
}
