import React from "react";
import gql from "graphql-tag";
import { useFieldState } from "informed";
import { useMutation } from "@apollo/react-hooks";
import styles from "./index.module.scss";
import { H3 } from "~/components/Typography";
import TextInput from "~/components/TextInput";
import Button from "~/components/Button";
import hasErrorCode from "~/helpers/hasErrorCode";

enum Field {
  Email = "AdminWipeDataForEmail-Email",
  Passcode = "AdminWipeDataForEmail-Passcode",
}

const WIPE_DATA_FOR_EMAIL = gql`
  mutation adminWipeDataForEmail($email: String!, $passcode: String!) {
    adminWipeDataForEmail(email: $email, passcode: $passcode) {
      webstarId
      externalId
      activeCampaignId
      auth0Deleted
    }
  }
`;

type Props = {
  onSuccess: (successMessage?: string) => void;
  onError: (errorMessage: string | undefined, errorCode?: string) => void;
};

const WipeDataForEmail = ({ onSuccess, onError }: Props) => {
  const emailState = useFieldState(Field.Email);
  const passcodeState = useFieldState(Field.Passcode);
  const [processing, setProcessing] = React.useState<boolean>(false);
  const [wipeDataForEmail] = useMutation(WIPE_DATA_FOR_EMAIL);

  const handleSubmit = async () => {
    setProcessing(true);
    onSuccess(undefined);
    onError(undefined);

    try {
      const response = await wipeDataForEmail({
        variables: {
          email: String(emailState.value),
          passcode: String(passcodeState.value),
        },
      });

      const data = response?.data?.adminWipeDataForEmail;
      setProcessing(false);

      onSuccess(
        `Successful! Webstar ID: ${
          data?.webstarId ? data.webstarId : "Undefined"
        }. External ID: ${
          data?.externalId ? data.externalId : "Undefined"
        }. Active Campaign ID: ${
          data?.activeCampaignId ? data.activeCampaignId : "Undefined"
        }. Auth0 User Deleted: ${data?.auth0Deleted ? "Yes" : "No"}.`
      );
    } catch (error) {
      onError(
        error.message,
        hasErrorCode(error, "NOT_ADMIN") ? "NOT_ADMIN" : undefined
      );

      setProcessing(false);
    }
  };

  return (
    <div className={styles.Content}>
      <H3>Wipe Data For Email</H3>

      <>
        <TextInput
          type="text"
          label="Email"
          placeholder="some.email@gmail.ca"
          field={Field.Email}
        />

        <TextInput
          type="text"
          label="Passcode"
          placeholder="Passcode"
          field={Field.Passcode}
        />

        <Button
          disabled={!emailState.value || !passcodeState.value || processing}
          onClick={handleSubmit}
        >
          {processing ? "Processing..." : "Process"}
        </Button>
      </>
    </div>
  );
};

export default WipeDataForEmail;
