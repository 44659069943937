import TagManager from "react-gtm-module";

const getGtmID = (): string => {
  return process.env.REACT_APP_GOOGLE_TAG_MANAGER ?? "";
};

export default function GTMProvider() {
  const isProduction = process.env.NODE_ENV === "production";
  const trackingID = getGtmID();

  if (isProduction && trackingID) {
    TagManager.initialize({
      gtmId: trackingID,
    });
  }

  return null;
}
