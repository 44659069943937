import { useFieldState } from "informed";
import React from "react";
import Button from "~/components/Button";
import TextInput from "~/components/TextInput";
import { H3, H4 } from "~/components/Typography";
import cleanNumberInput from "~/helpers/formatters/cleanNumberInput";
import styles from "./index.module.scss";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import hasErrorCode from "~/helpers/hasErrorCode";

enum Field {
  PolicyId = "AdminManualSinglePayment-PolicyId",
  Amount = "AdminManualSinglePayment-Amount",
  Passcode = "AdminManualSinglePayment-Passcode",
}

const MANUAL_SINGLE_PAYMENT = gql`
  mutation adminManualSinglePayment(
    $policyId: String!
    $amount: Float!
    $passcode: String!
  ) {
    adminManualSinglePayment(
      policyId: $policyId
      amount: $amount
      passcode: $passcode
    ) {
      singleChargeAmount
    }
  }
`;

type Props = {
  onSuccess: (successMessage?: string) => void;
  onError: (errorMessage: string | undefined, errorCode?: string) => void;
};

const ManualSinglePayment = ({ onSuccess, onError }: Props) => {
  const policyIdState = useFieldState(Field.PolicyId);
  const amountState = useFieldState(Field.Amount);
  const passcodeState = useFieldState(Field.Passcode);
  const [processing, setProcessing] = React.useState<boolean>(false);
  const [manualSinglePayment] = useMutation(MANUAL_SINGLE_PAYMENT);

  const handleSubmit = async () => {
    setProcessing(true);
    onSuccess(undefined);
    onError(undefined);

    try {
      const response = await manualSinglePayment({
        variables: {
          policyId: String(policyIdState.value),
          amount: cleanNumberInput(amountState.value, true),
          passcode: String(passcodeState.value),
        },
      });

      setProcessing(false);
      const data = response?.data?.adminManualSinglePayment;

      if (data.singleChargeAmount) {
        onSuccess(
          `User has been charged the amount of $${data.singleChargeAmount}.`
        );
      } else {
        onError("Operation Failed.");
      }
    } catch (error) {
      onError(
        error.message,
        hasErrorCode(error, "NOT_ADMIN") ? "NOT_ADMIN" : undefined
      );

      setProcessing(false);
    }
  };

  return (
    <div className={styles.Content}>
      <H3>Manual Single Payment</H3>

      <H4>
        Note: Payments created with this tool will be ignored when calculating
        total paid. This tool is intended to manually collect existing payments
        through FAC. If this tool is used to manually collect a scheduled
        recurring payment in a lump sum, please remember to cancel the existing
        recurring payment.
      </H4>

      <>
        <TextInput
          type="text"
          label="Policy ID"
          placeholder="AL-000000-AAAAAA"
          field={Field.PolicyId}
        />

        <TextInput
          type="text"
          label="Single Payment Amount"
          placeholder="0.00"
          inputMode="decimal"
          field={Field.Amount}
        />

        <TextInput
          type="text"
          label="Passcode"
          placeholder="Passcode"
          field={Field.Passcode}
        />

        <Button
          disabled={
            !policyIdState.value ||
            !amountState.value ||
            !passcodeState.value ||
            processing
          }
          onClick={handleSubmit}
        >
          {processing ? "Processing..." : "Process"}
        </Button>
      </>
    </div>
  );
};

export default ManualSinglePayment;
