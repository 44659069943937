import React from "react";
import classnames from "classnames";
import { PortalPolicyType } from "../../types";
import styles from "./index.module.scss";
import TypeImage from "~/components/TypeImage";
import Status from "~/components/Status";
import { Text, H4, TextExtraSmall, TextSmall } from "~/components/Typography";
import PortalNotificationCard from "~/components/PortalNotificationCard";
import PolicyDetailsCard from "~/components/PolicyDetailsCard";
import PortalPlanCard from "~/components/PortalPlanCard";
import PortalPaymentCard from "~/components/PortalPaymentCard";
import PortalPolicyDocuments from "~/components/PortalPolicyDocuments";
import { formatCurrency } from "~/helpers/currency";
import { format } from "date-fns";
import {
  getTypeOfCoverage,
  parseCoveredItems,
  parseNotCoveredItems,
} from "~/helpers/getCoverageData";
import PortalPaymentMethodCard from "../PortalPaymentMethodCard";
import { PAYMENT_STATUS, UPDATABLE_PAYMENT_STATUS } from "~/helpers/constants";
import { localDateFromIso } from "~/helpers/dates";
import Note from "../Note";

type PropsType = {
  policy: PortalPolicyType;
};

const PolicyDetails = ({ policy }: PropsType) => {
  const coverageType = getTypeOfCoverage(policy.typeOfCover);

  const cardIsUpdateable = UPDATABLE_PAYMENT_STATUS.includes(policy.status);

  return (
    <article className={styles.Article}>
      <div className={classnames(styles.Col, styles.Info)}>
        <div className={styles.Image}>
          <TypeImage
            type={policy.policyObject === "auto" ? "motor" : "home"}
            vehicleMake={
              policy.policyObject === "auto" ? policy.vehicle.make : undefined
            }
            variant="small"
          />
        </div>

        <div className={styles.Header}>
          <H4 component="h2">{policy.insuredObject}</H4>

          <TextExtraSmall>
            {`Policy No. ${policy.externalId}`}
            <Status status={policy.status} />
          </TextExtraSmall>
        </div>

        <div className={styles.HeaderType}>
          {policy.premium?.schedule === "monthly" && (
            <>
              <TextSmall>{coverageType?.title}</TextSmall>

              <TextSmall fontWeight="bold">
                {formatCurrency(policy.premium.amount)} per month
              </TextSmall>
            </>
          )}
        </div>

        <div className={styles.HeaderExpiry}>
          <TextSmall>Expiry Date</TextSmall>

          <TextSmall fontWeight="bold">
            {format(localDateFromIso(policy.coverageEnd), "MMM dd, yyyy")}
          </TextSmall>
        </div>

        <div className={styles.HeaderPremium}>
          <TextSmall>Annual Premium</TextSmall>

          <TextSmall fontWeight="bold">
            {formatCurrency(policy.premium?.totalValue as number, false)}
          </TextSmall>
        </div>
      </div>

      <div className={classnames(styles.Col, styles.NextPayment)}>
        <PortalNotificationCard
          policy={{
            externalId: policy.externalId,
            policyObject: policy.policyObject,
          }}
          notificationData={policy.payment}
        />
      </div>

      <div className={styles.Col}>
        <PortalPlanCard policy={policy} />
      </div>

      <div className={styles.Col}>
        <PortalPolicyDocuments documents={policy.documents} />
      </div>

      {coverageType && (
        <div className={classnames(styles.Col, styles.Coverage)}>
          <PolicyDetailsCard
            className={styles.Covered}
            body={policy.coverage
              .map((cover) => {
                return parseCoveredItems(cover, coverageType.keyContent);
              })
              .filter((cover) => {
                return cover.text;
              })}
          />

          <PolicyDetailsCard
            className={styles.NotCovered}
            isCovered={false}
            body={parseNotCoveredItems(coverageType.keyContent)}
          />
        </div>
      )}

      {policy.premium?.schedule === "monthly" && (
        <div className={styles.Col}>
          <PortalPaymentCard
            annualPremium={policy.premium?.totalValue}
            monthlyPremium={policy.premium?.amount}
            nextPaymentDate={policy.premium?.nextPaymentDate}
            finalPaymentDate={policy.premium?.finalPaymentDate}
            policyObject={policy.policyObject}
          />
        </div>
      )}

      <div className={styles.Col}>
        {(policy.payment.status !== PAYMENT_STATUS.noPaymentFound &&
          policy.payment.status !== PAYMENT_STATUS.registered && (
            <PortalPaymentMethodCard
              cardIsUpdateable={cardIsUpdateable}
              finalCardNumbers={policy.payment.finalCardNumbers as string}
              policyId={policy.externalId}
            />
          )) || (
          <Text>No payment found. Please, complete your policy payment.</Text>
        )}
      </div>
      <div className={styles.Col}>
        {
          <Note
            className={styles.Note}
            mainText={
              "Please, contact support if you need to change any details about your policy."
            }
            noMaxWidth
          >
            <div className={styles.NoteContactChild}>
              <div className={styles.CallUs}>
                <Text>Call us</Text>
              </div>

              <div className={styles.EmailUs}>
                <Text>Email us</Text>
              </div>

              <div className={styles.ContactPhone}>
                <Text fontWeight="bold">246-850-6800</Text>
              </div>

              <div className={styles.ContactEmail}>
                <Text fontWeight="bold">hello@almi.bb</Text>
              </div>
            </div>
          </Note>
        }
      </div>

      <div className={styles.Col}>
        <div className={styles.Spacing}>
          <TextSmall>
            *Please refer to your policy schedule for all details.
          </TextSmall>
          {policy.typeOfCover === "C" && (
            <TextSmall>
              **For the lesser of a week or duartion of repair
            </TextSmall>
          )}
        </div>
      </div>
    </article>
  );
};

export default PolicyDetails;
