/* eslint-disable no-magic-numbers */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useApolloClient } from "@apollo/react-hooks";
import gql from "graphql-tag";
import jwtDecode from "jwt-decode";
import { useMotorContext } from "~/contexts/MotorProvider";
import { IUserData, useUserContext } from "~/contexts/UserProvider";
import { useOnboardContext } from "~/contexts/OnboardProvider";
import { useAuth0Context } from "~/contexts/Auth0Context";
import { H3, TextSmall, A } from "~/components/Typography";
import TextInput from "~/components/TextInput";
import SubmitButton from "~/components/SubmitButton";
import CheckBox from "~/components/Checkbox";
import { CountryCode, InsuranceType, MutationMethod } from "../../../types";
import formMotorPolicyPayload from "~/helpers/formMotorPolicyPayload";
import styles from "./index.module.scss";
import MotorQuoteLoading from "~/components/MotorQuoteLoading";
import Loading from "~/components/Loading";
import AppError from "~/helpers/AppError";
import { validateEmail } from "~/helpers/validators";
import { useUpdateAssistant } from "~/helpers/useUpdateAssistant";
import { useHomeContext } from "~/contexts/HomeProvider";
import formHomePolicyPayload from "~/helpers/formHomePolicyPayload";
import { useCustomFieldState } from "~/helpers/hooks/useCustomFieldState";
import { useCustomFieldApi } from "~/helpers/hooks/useCustomFieldApi";
import { useHeapContext, HeapEventName } from "~/contexts/HeapProvider";
import AlreadyHaveAccount from "~/components/AlreadyHaveAccount";
import { useMountEffect } from "~/helpers/hooks/useMountEffect";
import pushWithParams from "~/helpers/pushWithParams";
import { GONE_TOO_FAR } from "~/routes/index.constant";

const CREATE_MOTOR_POLICY = gql`
  mutation createPolicy($createPolicyInput: CreatePolicyInput!) {
    createPolicy(input: $createPolicyInput)
  }
`;

const CREATE_HOME_POLICY = gql`
  mutation createHomePolicy($createHomePolicyInput: CreateHomePolicyInput!) {
    createHomePolicy(input: $createHomePolicyInput)
  }
`;

const USER_EXISTS = gql`
  query userExists($input: String!) {
    userExists(input: $input) {
      exists
    }
  }
`;

type DecodedToken = {
  email: string;
  email_verified: boolean;
};

type Props = {
  nextPath?: string;
  insuranceType: InsuranceType;
  countryCode: CountryCode;
  setCloseConfirm?: (value: boolean) => void;
};

const userExistsError =
  "A user exists with this email. Please log into your account.";

const AccountEmail = ({
  nextPath,
  insuranceType,
  countryCode,
  setCloseConfirm,
}: Props) => {
  const apolloClient = useApolloClient();
  const auth0 = useAuth0Context();
  const motorCtx = useMotorContext();
  const homeCtx = useHomeContext();
  const userCtx = useUserContext();
  const onboardContext = useOnboardContext();
  const heapCtx = useHeapContext();

  const [isValidatingEmail, setIsValidatingEmail] = useState(false);
  const [isAcceptableEmail, setIsAcceptableEmail] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isCreated, setIsCreated] = useState(false);
  const [pageError, setPageError] = useState(false);
  const [hideAssistant, setHideAssistant] = useState(false);
  const [savedPolicy, setSavedPolicy] = useState<
    { id: string; email: string; terms: boolean } | undefined
  >();
  const [, setError] = useState();

  const history = useHistory();
  const emailFieldName = "email";
  const termsFieldName = "termsAndConditions";
  const emailApi = useCustomFieldApi(emailFieldName);
  const termsState = useCustomFieldState(termsFieldName);
  const emailValue = emailApi.getValue();

  useUpdateAssistant({
    isOpen: !hideAssistant,
    text: (
      <TextSmall>
        Enter your email to receive a copy of your quote and continue later.
      </TextSmall>
    ),
  });

  const getMutationType = (insuranceType: InsuranceType) => {
    switch (insuranceType) {
      case "motor":
        return CREATE_MOTOR_POLICY;
      case "home":
        return CREATE_HOME_POLICY;
    }
  };

  const getAssociation = () => {
    switch (insuranceType) {
      case "motor":
        return motorCtx.savingsInfo.data.association;
      case "home":
        return "";
    }
  };

  const getPhoneNumber = () => {
    return userCtx.phoneNumber;
  };

  const getOccupation = () => {
    switch (insuranceType) {
      case "motor":
        return motorCtx.savingsInfo.data.occupation;
      case "home":
        return homeCtx.occupation;
    }
  };

  const getPolicyInputName = (
    method: MutationMethod,
    insuranceType: InsuranceType
  ) => {
    let policyInputName = method;

    switch (insuranceType) {
      case "motor":
        policyInputName += "PolicyInput";
        break;
      case "home":
        policyInputName += "HomePolicyInput";
        break;
    }

    return policyInputName;
  };

  const getErrorRedirect = (
    countryCode: CountryCode,
    insuranceType: InsuranceType
  ) => {
    switch (insuranceType) {
      case "motor":
        return `/${countryCode}/motor/association`;
      case "home":
        return `/${countryCode}/home/insurance-review`;
    }
  };
  //check for mandatory values before continuing
  const motorPolicyCheck = [
    "branch",
    "coverageStart",
    "engineSize",
    "value",
    "year",
    "make",
    "model",
    "isSportsCar",
    "yearsWithoutClaims",
    "association",
    "age",
    "yearsDriving",
    "isShiftWorker",
    "isEngineModified",
    "isLeftSide",
    "drivers",
  ];

  const homePolicyCheck = [
    "branch",
    "allRiskCoverage",
    "constructionType",
    "contentsValue",
    "coverageStart",
    "isCoastal",
    "property",
  ];

  const keyExists = (obj: object, key: string): boolean => {
    if (!obj || (typeof obj !== "object" && !Array.isArray(obj))) {
      return false;
    } else if (obj.hasOwnProperty(key) && obj[key] !== undefined) {
      return true;
    } else if (Array.isArray(obj)) {
      for (let i = 0; i < obj.length; i++) {
        const result = keyExists(obj[i], key);
        if (result) {
          return result;
        }
      }
    } else {
      for (const k in obj) {
        const result = keyExists(obj[k], key);
        if (result) {
          return result;
        }
      }
    }

    return false;
  };
  const checkPayloadValid = (policyPayload: object) => {
    for (const prop of insuranceType === "motor"
      ? motorPolicyCheck
      : homePolicyCheck) {
      if (!keyExists(policyPayload, prop)) {
        return false;
      }
    }
    return true;
  };

  const createPolicy = async (email: string, terms: boolean) => {
    //Confirm the policy has not already been created
    if (
      !(
        (insuranceType === "motor" && motorCtx?.policyInfo?.data?.id) ||
        (insuranceType === "home" && homeCtx?.policyId)
      )
    ) {
      setIsSubmitting(true);
      setIsLoading(true);

      try {
        if (!email || !terms) {
          setError(() => {
            throw new Error("Email address or terms not available");
          });
        }

        setHideAssistant(true);

        let policyPayload;
        let policyIsValid;
        switch (insuranceType) {
          case "motor":
            heapCtx.track(HeapEventName.MOTOR_EMAIL_ADDRESS, {});
            policyPayload = formMotorPolicyPayload(
              motorCtx,
              email,
              !!onboardContext.paymentIsRecurring,
              countryCode,
              true
            );
            policyIsValid = checkPayloadValid(policyPayload);
            break;
          case "home":
            heapCtx.track(HeapEventName.HOME_EMAIL_ADDRESS, {});
            policyPayload = formHomePolicyPayload(
              homeCtx,
              email,
              !!onboardContext.paymentIsRecurring,
              countryCode,
              true
            );
            policyIsValid = checkPayloadValid(policyPayload);

            break;
        }

        const policyMutation = policyIsValid
          ? await apolloClient.mutate({
              mutation: getMutationType(insuranceType),
              variables: {
                [getPolicyInputName("create", insuranceType)]: {
                  individual: {
                    email,
                    countryCode,
                    termsAndConditions: terms,
                    occupation: getOccupation(),
                    association: getAssociation(),
                  },
                  policy: policyPayload,
                  phoneNumber: getPhoneNumber(),
                },
              },
            })
          : {};

        if (policyIsValid && policyMutation.data) {
          setSavedPolicy({
            id:
              insuranceType === "motor"
                ? policyMutation.data.createPolicy
                : policyMutation.data.createHomePolicy,
            email,
            terms,
          });

          if (window.vgo) {
            window.vgo(
              "setEmail",
              `${emailValue ? emailValue.toString().toLowerCase() : emailValue}`
            );
          }

          if (userCtx.email && heapCtx.initialized) {
            heapCtx.checkForProfileId(userCtx.email);
          }

          setIsLoading(false);
          setIsCreated(true);
        } else if (!policyIsValid) {
          pushWithParams(history, `${GONE_TOO_FAR}?message=missing-values`); // currently something went wrong is thrown first
        } else {
          setIsLoading(false);
          setPageError(true);
          setIsSubmitting(false);
          setHideAssistant(false);
        }
      } catch (error) {
        setIsLoading(false);
        setPageError(true);
        setIsSubmitting(false);
        setHideAssistant(false);
      }
    }
  };

  useEffect(() => {
    if (
      savedPolicy &&
      !motorCtx.policyInfo.data.id &&
      insuranceType === "motor"
    ) {
      motorCtx.policyInfo.setItem("id", savedPolicy.id);
    }

    if (savedPolicy && !homeCtx.policyId && insuranceType === "home") {
      homeCtx.setState({
        policyId: savedPolicy.id,
      });
    }

    if (savedPolicy) {
      if (!userCtx.email || userCtx.email !== savedPolicy.email) {
        const updateUser: IUserData = {
          email: savedPolicy.email,
          termsAndConditions: savedPolicy.terms,
        };
        /*
          Reset data is existing data does not match the email used to login
        */
        if (userCtx.email !== savedPolicy.email) {
          updateUser.name = undefined;
          updateUser.phoneNumber = undefined;
          updateUser.countryPhoneCode = undefined;
        }
        userCtx.setState(updateUser);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedPolicy]);

  /*
    if there is already a policy in the context, redirect to the nextpath
  */
  useMountEffect(() => {
    if (motorCtx.policyInfo.data.id && nextPath) {
      history.push(nextPath);
    }
  });

  /*
     If the user is already signed in create the policy and move to the next screen
  */
  useEffect(() => {
    if (auth0.token) {
      const tokenObj: DecodedToken = jwtDecode(auth0.token.idToken);
      if (!motorCtx.policyInfo.data.id || !homeCtx.policyId) {
        createPolicy(tokenObj.email, true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth0.token]);

  useEffect(() => {
    setIsValidatingEmail(true);
    const hasValidEmailFormat = emailValue && !validateEmail(emailValue);
    if (!hasValidEmailFormat) {
      setIsAcceptableEmail(false);
      setIsValidatingEmail(false);
      return;
    }

    setIsAcceptableEmail(true);
    const checkEmail = async () => {
      try {
        const { data } = await apolloClient.query({
          query: USER_EXISTS,
          variables: {
            input: emailValue
              ? emailValue.toString().toLowerCase()
              : emailValue,
          },
        });
        if (data.userExists.exists) {
          emailApi.setError(userExistsError);
          setIsAcceptableEmail(false);
        } else {
          emailApi.setError(undefined);
        }
        emailApi.setTouched(true);
        setIsValidatingEmail(false);
      } catch (error) {
        setIsValidatingEmail(false);

        if (error?.message?.includes("Failed to fetch")) {
          setPageError(true);
        } else {
          // Make error boundary catch this
          setError(() => {
            throw error;
          });
        }
      }
    };

    const wait = 500;
    const timeout = setTimeout(() => checkEmail(), wait);
    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailApi, emailValue]);

  if (pageError) {
    throw new AppError(
      "Hmmm. Something isn't quite right. Please call us at 246-850-6800 to help sort it out.",
      {
        mainButton: {
          text: "Go Back",
          onClick: () =>
            history.push(getErrorRedirect(countryCode, insuranceType)),
        },
      }
    );
  }

  const handleNextClick = () => {
    createPolicy(
      emailValue ? emailValue.toString().toLowerCase() : (emailValue as string),
      termsState.value as boolean
    );
  };

  const handleLoginClick = () => {
    if (setCloseConfirm) {
      setCloseConfirm(false);
    }
    auth0.login(history.location.pathname)();
  };

  if (auth0.isLoading() || isLoading) {
    return (
      <div className={styles.LoadingWrapper}>
        <Loading />
      </div>
    );
  }
  const label = "What's your email address?";

  return (
    <>
      <H3 className={styles.Subtitle} component="h1">
        {label}
      </H3>
      <div className={styles.InputWrapper}>
        <TextInput
          hideLabelFromView
          label={label}
          field={emailFieldName}
          placeholder="Email address"
          keepState
          type="email"
          autoComplete="email"
          validate={(value) => {
            if (emailApi.getError()) return emailApi.getError();
            return validateEmail(value);
          }}
          validateOnBlur
          autoFocus
          className={styles.TextInput}
        />
        <div className={styles.CheckBoxWrapper}>
          <CheckBox
            field={termsFieldName}
            label={
              <>
                I agree to Almi&apos;s{" "}
                <A
                  href="https://almi.bb/terms-of-use/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms &amp; Conditions
                </A>{" "}
                and{" "}
                <A
                  href="https://almi.bb/privacy-policy/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy.
                </A>
              </>
            }
            labelClassName={styles.CheckboxLabel}
          />
        </div>
      </div>

      <div className={styles.ButtonWrapper}>
        <SubmitButton
          id={"Email-SubmitButton"}
          disabled={
            isValidatingEmail ||
            !isAcceptableEmail ||
            !termsState.value ||
            isSubmitting ||
            emailApi.getError()
          }
          className={styles.Button}
          onClick={handleNextClick}
        >
          Next
        </SubmitButton>

        <AlreadyHaveAccount
          wrapperStyle={styles.LoginWrapper}
          textStyle={styles.LoginText}
          onLoginClick={handleLoginClick}
        />
      </div>

      <MotorQuoteLoading
        isDone={isCreated}
        isLoading={isSubmitting}
        insuranceType={insuranceType}
        onFinished={() => {
          setIsSubmitting(false);
          if (nextPath) history.push(nextPath);
        }}
      />
    </>
  );
};

export default AccountEmail;
