import { useFormState, RadioGroup, Radio } from "informed";
import React from "react";
import Button from "~/components/Button";
import TextInput from "~/components/TextInput";
import TextListInput from "~/components/TextInput/TextListInput";
import { H3, TextSmall } from "~/components/Typography";
import styles from "./index.module.scss";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import hasErrorCode from "~/helpers/hasErrorCode";

enum Field {
  Make = "make",
  ModelName = "modelName",
  ModelYearStart = "model-year-start",
  ModelYearEnd = "model-year-end",
  ProductionYearStart = "production-year-start",
  ProductionYearEnd = "production-year-end",
  CountryOfOrigin = "countryOfOrigin",
  Classification = "classification",
  IsSportsCar = "isSportsCar",
  Passcode = "passcode",
  TrimTypes = "trim-types",
}

const NEW_VEHICLE = gql`
  mutation adminNewVehicle($vehicle: VehicleInput!, $passcode: String!) {
    adminNewVehicle(vehicle: $vehicle, passcode: $passcode) {
      success
    }
  }
`;

type Props = {
  onSuccess: (successMessage?: string) => void;
  onError: (errorMessage: string | undefined, errorCode?: string) => void;
};

const ManualSinglePayment = ({ onSuccess, onError }: Props) => {
  const [processing, setProcessing] = React.useState<boolean>(false);
  const [newVehicleMutation] = useMutation(NEW_VEHICLE);
  const { values } = useFormState();

  const handleSubmit = async () => {
    setProcessing(true);
    onSuccess(undefined);
    onError(undefined);

    try {
      const {
        make,
        modelName,
        classification,
        countryOfOrigin,
        passcode,
      } = values;
      const modelStart = parseInt(String(values[Field.ModelYearStart]), 10);
      const modelEnd = parseInt(String(values[Field.ModelYearEnd]), 10);
      const productionStart = parseInt(
        String(values[Field.ProductionYearStart]),
        10
      );
      const productionEnd = parseInt(
        String(values[Field.ProductionYearEnd]),
        10
      );
      // The "trim-types" input is a custom informed field that returns a list of strings.
      const trims =
        values[Field.TrimTypes] === undefined
          ? []
          : (values[Field.TrimTypes] as string[]);
      const response = await newVehicleMutation({
        variables: {
          passcode,
          vehicle: {
            make,
            modelName,
            classification,
            countryOfOrigin,
            displayMake: true,
            isSportsCar: values.isSportsCar === "yes",
            productionYearRanges: [
              { start: productionStart, end: productionEnd },
            ],
            modelYearRanges: [{ start: modelStart, end: modelEnd }],
            trimOptions:
              values.isSportsCar === "no"
                ? trims.map((trimName) => ({
                    trimName,
                    isSportsCar: true,
                  }))
                : [],
          },
        },
      });

      setProcessing(false);
      const success = response?.data?.adminNewVehicle.success;

      if (success) {
        onSuccess("Vehicle added.");
      } else {
        onError("Could not add vehicle.");
      }
    } catch (error) {
      onError(
        error.message,
        hasErrorCode(error, "NOT_ADMIN") ? "NOT_ADMIN" : undefined
      );

      setProcessing(false);
    }
  };

  return (
    <div className={styles.Content}>
      <H3>Add New Vehicle to Database</H3>

      <div className={styles.Form}>
        <div className={styles.Column}>
          <TextInput
            type="text"
            field={Field.Make}
            label="Vehicle Make"
            placeholder="Toyota"
          />
          <TextInput
            type="text"
            field={Field.ModelName}
            label="Vehicle Model"
            placeholder="Camry"
          />
          <div className={styles.YearFields}>
            <TextInput
              type="number"
              field={Field.ModelYearStart}
              label="Model Year (Start Year)"
              placeholder="1998"
              size={4}
            />
            <TextInput
              type="number"
              field={Field.ModelYearEnd}
              label="Model Year (End Year)"
              placeholder="1998"
              size={4}
            />
          </div>
          <div className={styles.YearFields}>
            <TextInput
              type="number"
              field={Field.ProductionYearStart}
              label="Production Year (Start Year)"
              placeholder="1998"
              size={4}
            />
            <TextInput
              type="number"
              field={Field.ProductionYearEnd}
              label="Production Year (End Year)"
              placeholder="1998"
              size={4}
            />
          </div>
          <TextInput
            type="text"
            field={Field.CountryOfOrigin}
            label="Country of Origin"
            placeholder="Japan"
          />
          <TextInput
            type="text"
            field={Field.Classification}
            label="Classification"
            placeholder="Compact"
          />
        </div>
        <div className={styles.Column}>
          <div>
            <TextSmall>Is it a sports car?</TextSmall>
            <div className={styles.RadioGroup}>
              <RadioGroup field={Field.IsSportsCar}>
                <span className={styles.RadioButton}>
                  <Radio id={`${Field.IsSportsCar}Yes`} value="yes" />
                  <label htmlFor={`${Field.IsSportsCar}Yes`}>
                    <TextSmall>Yes</TextSmall>
                  </label>
                </span>
                <span className={styles.RadioButton}>
                  <Radio id={`${Field.IsSportsCar}No`} value="no" />
                  <label htmlFor={`${Field.IsSportsCar}No`}>
                    <TextSmall>No</TextSmall>
                  </label>
                </span>
              </RadioGroup>
            </div>
          </div>
          {values.isSportsCar === "no" && (
            <div>
              <TextListInput
                label="Please input the trim types of the car."
                field={Field.TrimTypes}
                placeholder="Trim Type R"
                addValueButtonText="New Trim"
              />
              <TextSmall>
                * Each trim will be registered as a Sport Car because of its
                trim type.
              </TextSmall>
            </div>
          )}
          <TextInput
            type="text"
            field={Field.Passcode}
            label="Passcode"
            placeholder="Passcode"
          />
        </div>
      </div>
      <div className={styles.Form}>
        <Button disabled={processing} onClick={handleSubmit}>
          {processing ? "Processing..." : "Register"}
        </Button>
      </div>
    </div>
  );
};

export default ManualSinglePayment;
