import React from "react";
import gql from "graphql-tag";
import { useFieldState } from "informed";
import { useMutation } from "@apollo/react-hooks";
import styles from "./index.module.scss";
import { H3 } from "~/components/Typography";
import TextInput from "~/components/TextInput";
import Button from "~/components/Button";
import hasErrorCode from "~/helpers/hasErrorCode";

enum Field {
  PolicyId = "AdminCancelPolicy-PolicyId",
  Passcode = "AdminCancelPolicy-Passcode",
}

const CANCEL_POLICY = gql`
  mutation AdminCancelPolicy($externalId: String!, $passcode: String!) {
    adminCancelPolicy(externalId: $externalId, passcode: $passcode) {
      webstarId
      externalId
      activeCampaignDealId
    }
  }
`;

type Props = {
  onSuccess: (successMessage?: string) => void;
  onError: (errorMessage: string | undefined, errorCode?: string) => void;
};

const AdminCancelPolicy = ({ onSuccess, onError }: Props) => {
  const policyIdState = useFieldState(Field.PolicyId);
  const passcodeState = useFieldState(Field.Passcode);
  const [processing, setProcessing] = React.useState<boolean>(false);
  const [cancelPolicy] = useMutation(CANCEL_POLICY);

  const handleSubmit = async () => {
    setProcessing(true);
    onSuccess(undefined);
    onError(undefined);

    try {
      const response = await cancelPolicy({
        variables: {
          externalId: String(policyIdState.value),
          passcode: String(passcodeState.value),
        },
      });

      const data = response?.data?.adminCancelPolicy;
      setProcessing(false);

      onSuccess(
        `Successful! Webstar ID: ${
          data?.webstarId ? data.webstarId : "Undefined"
        }. External ID: ${
          data?.externalId ? data.externalId : "Undefined"
        }. Active Campaign Deal ID: ${
          data?.activeCampaignDealId ? data.activeCampaignDealId : "Undefined"
        }.`
      );
    } catch (error) {
      onError(
        error.message,
        hasErrorCode(error, "NOT_ADMIN") ? "NOT_ADMIN" : undefined
      );

      setProcessing(false);
    }
  };

  return (
    <div className={styles.Content}>
      <H3>Cancel Policy</H3>

      <>
        <TextInput
          type="text"
          label="Policy ID"
          placeholder="AL20XX0000-A0A0A0"
          field={Field.PolicyId}
        />

        <TextInput
          type="text"
          label="Passcode"
          placeholder="Passcode"
          field={Field.Passcode}
        />

        <Button
          disabled={!policyIdState.value || processing}
          onClick={handleSubmit}
        >
          {processing ? "Processing..." : "Process"}
        </Button>
      </>
    </div>
  );
};

export default AdminCancelPolicy;
