import { Option, Select, useFieldState, useFormApi } from "informed";
import React from "react";
import CancelRecurringPayment from "./AdminCancelRecurringPayment";
import { Bold, H4, Text } from "~/components/Typography";
import Loading from "~/components/Loading";
import styles from "./index.module.scss";
import AlertImage from "~/assets/images/alert.png";
import UpdatePolicyPremium from "./AdminUpdatePolicyPremium";
import ManualSinglePayment from "./AdminManualSinglePayment";
import WipeDataForEmail from "./AdminWipeDataForEmail";
import AdminCancelPolicy from "./AdminCancelPolicy";
import AdminRegenerateContract from "./AdminRegenerateContract";
import AdminRegenerateCoverNote from "./AdminRegenerateCoverNote";
import AdminNewVehicle from "./AdminNewVehicle";
import AdminGetLatePaymentView from "./AdminGetLatePaymentView";
import { useMountEffect } from "~/helpers/hooks/useMountEffect";
import { useAuth0Context } from "~/contexts/Auth0Context";
import jwtDecode from "jwt-decode";

//TODO add to common package
export enum AdminRoleType {
  admin = "ADMIN",
  cxo = "CXO",
  readonly = "READONLY",
}

type ControlProps = {
  onSuccess: (successMessage?: string) => void;
  onError: (errorMessage: string | undefined, errorCode?: string) => void;
};

type AdminControlType = {
  url: string;
  title: string;
  element: React.FC<ControlProps>;
  allowedRoles: AdminRoleType[];
};

const ADMIN_CONTROL: { [key: string]: AdminControlType } = {
  updatePolicyPremium: {
    url: "update-policy-premium",
    title: "Update Policy Premium",
    element: UpdatePolicyPremium,
    allowedRoles: [AdminRoleType.admin],
  },
  createManualPayment: {
    url: "create-manual-payment",
    title: "Create Manual Payment",
    element: ManualSinglePayment,
    allowedRoles: [AdminRoleType.admin],
  },
  cancelRecurringPayment: {
    url: "cancel-recurring-payment",
    title: "Cancel Recurring Payment",
    element: CancelRecurringPayment,
    allowedRoles: [AdminRoleType.admin],
  },
  wipeDataForEmail: {
    url: "wipe-data-for-email",
    title: "Wipe Data For Email",
    element: WipeDataForEmail,
    allowedRoles: [AdminRoleType.admin],
  },
  cancelPolicy: {
    url: "cancel-policy",
    title: "Cancel Policy",
    element: AdminCancelPolicy,
    allowedRoles: [AdminRoleType.admin],
  },
  regenerateContract: {
    url: "regenerate-contract",
    title: "Regenerate Contract",
    element: AdminRegenerateContract,
    allowedRoles: [AdminRoleType.admin, AdminRoleType.cxo],
  },
  regenerateCoverNote: {
    url: "regenerate-cover-note",
    title: "Regenerate Cover Note",
    element: AdminRegenerateCoverNote,
    allowedRoles: [AdminRoleType.admin, AdminRoleType.cxo],
  },
  newVehicle: {
    url: "new-vehicle",
    title: "Input New Vehicles",
    element: AdminNewVehicle,
    allowedRoles: [AdminRoleType.admin, AdminRoleType.cxo],
  },
  latePaymentView: {
    url: "late-payment-view",
    title: "Late Payments CSV",
    element: AdminGetLatePaymentView,
    allowedRoles: [
      AdminRoleType.admin,
      AdminRoleType.cxo,
      AdminRoleType.readonly,
    ],
  },
};

const AdminDashboard = () => {
  const formApi = useFormApi();
  const controlFieldName = "Admin-ControlSelectField";
  const auth0context = useAuth0Context();
  const controlFieldState = useFieldState(controlFieldName);
  const [roles, setRoles] = React.useState<AdminRoleType[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [success, setSuccess] = React.useState<string>();
  const [error, setError] = React.useState<string>();

  useMountEffect(() => {
    const roleUrl = "https://app.almi.com/roles";

    if (roleUrl) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unnecessary-type-assertion
      const decoded = jwtDecode(auth0context.token?.idToken ?? "") as any;

      if (decoded?.[roleUrl] && decoded?.[roleUrl]?.length > 0) {
        setRoles(decoded[roleUrl]);
      }
    }

    setLoading(false);
  });

  React.useEffect(() => {
    if (success) {
      formApi.reset();
    }
  }, [success, formApi]);

  const handleSuccess = (message?: string) => {
    if (message) {
      setSuccess(message);
      window.scroll({ top: 0 });
    } else {
      setSuccess(undefined);
    }
  };

  const handleError = (message: string | undefined, code?: string) => {
    if (message) {
      const messageSplit = message.split(":");
      const parsedMessage = messageSplit[messageSplit.length - 1].trim();
      setError(parsedMessage);
      window.scroll({ top: 0 });
    } else {
      setError(undefined);
    }

    if (code === "NOT_ADMIN") {
      window.location.reload();
    }
  };

  const getControlComponent = () => {
    const name = Object.keys(ADMIN_CONTROL).find((name) => {
      return ADMIN_CONTROL[name].url === String(controlFieldState?.value);
    });

    if (name) {
      const SelectedControl = ADMIN_CONTROL[name].element;

      return (
        <SelectedControl onSuccess={handleSuccess} onError={handleError} />
      );
    }

    return undefined;
  };

  if (loading) {
    return (
      <div className={styles.Loading}>
        <Loading />
      </div>
    );
  } else if (roles.length) {
    return (
      <div className={styles.Container}>
        <Select
          className={styles.Dropdown}
          field={controlFieldName}
          onChange={() => {
            setError(undefined);
            setSuccess(undefined);
          }}
        >
          <Option value={""} disabled>
            Select a Control Panel
          </Option>

          <>
            {Object.keys(ADMIN_CONTROL).map((name) => {
              if (
                ADMIN_CONTROL[name].allowedRoles.filter((roleName) =>
                  roles.includes(roleName)
                ).length > 0
              ) {
                return (
                  <Option key={name} value={ADMIN_CONTROL[name].url}>
                    {ADMIN_CONTROL[name].title}
                  </Option>
                );
              } else {
                return <React.Fragment key={name}></React.Fragment>;
              }
            })}
          </>
        </Select>

        <hr />

        {success ? <H4 className={styles.Success}>{success}</H4> : undefined}
        {error ? <H4 className={styles.Error}>{error}</H4> : undefined}

        {getControlComponent()}
      </div>
    );
  } else {
    return (
      <div className={styles.NoAccessWrapper}>
        <img className={styles.NoAccessAlert} src={AlertImage} alt="Alert" />
        <H4 className={styles.NoAccessHeading}>Access Denied</H4>

        <Text className={styles.NoAccessText}>
          Unfortunately you do not have access to this link. Please contact us
          at <Bold>almi.bb/support</Bold> for access.
        </Text>
      </div>
    );
  }
};

export default AdminDashboard;
