import Button from "~/components/Button";
import TextInput from "~/components/TextInput";
import { H3 } from "~/components/Typography";
import gql from "graphql-tag";
import React, { useEffect } from "react";
import { useFieldState } from "informed";
import styles from "./index.module.scss";
import hasErrorCode from "~/helpers/hasErrorCode";
import { useQuery } from "@apollo/react-hooks";

enum Field {
  Passcode = "AdminGetLatePaymentView-Passcode",
}

const DECIMAL_PRECISION = 2;

const GET_LATE_PAYMENTS = gql`
  query adminGetLatePaymentView($passcode: String!) {
    adminGetLatePaymentView(passcode: $passcode) {
      policies {
        policyId
        clientName
        policyStartDate
        scheduledPayments {
          paymentDate
          amount
          status
          lastModified
          paymentRecordId
        }
      }
    }
  }
`;

type LatePaymentPolicy = {
  policyId: string;
  clientName: string;
  policyStartDate: string;
  scheduledPayments: Array<{
    paymentDate: Date;
    amount: string;
    status: string;
    lastModified: Date;
    paymentRecordId?: string;
  }>;
};

const convertToCSV = (policies: LatePaymentPolicy[]) => {
  let paymentFields = 1;

  policies.forEach((policy) => {
    policy.scheduledPayments.some((payment, index) => {
      if (payment.status === "pending") {
        if (index > paymentFields) {
          paymentFields = index;
        }

        return true;
      }

      return false;
    });
  });

  const columns = [
    "Policy ID",
    "Client Name",
    "Policy Start Date",
    ...(() => {
      const paymentColumns = [];

      for (let i = 0; i < paymentFields; i++) {
        paymentColumns.push(
          `Payment ${i + 1}`,
          `Payment ${i + 1}`,
          `Payment ${i + 1}`
        );
      }

      return paymentColumns;
    })(),
    "",
    "Total Late",
    "Total Paid",
    "Total Expected",
  ];

  const separator = ",";
  const newLine = "\n";

  let csvText = "";

  //Add column headers to csv text
  columns.forEach((title, index) => {
    csvText = csvText.concat(
      title,
      index === columns.length - 1 ? newLine : separator
    );
  });

  policies.forEach((policy: LatePaymentPolicy) => {
    csvText = csvText.concat(
      policy.policyId,
      separator,
      policy.clientName,
      separator,
      new Date(policy.policyStartDate).toDateString(),
      separator
    );

    let totalLate = 0;
    let totalPaid = 0;
    let totalExpected = 0;

    for (let i = 0; i < paymentFields; i++) {
      if (
        policy.scheduledPayments[i] &&
        policy.scheduledPayments[i].status !== "pending"
      ) {
        const payment = policy.scheduledPayments[i];

        switch (payment.status) {
          case "late":
            totalLate += Number(payment.amount);
            totalExpected += Number(payment.amount);
            break;
          case "paid":
            totalPaid += Number(payment.amount);
            totalExpected += Number(payment.amount);
            break;
        }

        csvText = csvText.concat(
          new Date(payment.paymentDate).toDateString(),
          separator,
          payment.amount,
          separator,
          payment.status.charAt(0).toUpperCase() + payment.status.slice(1),
          separator
        );
      } else {
        csvText = csvText.concat(separator, separator, separator);
      }
    }

    csvText = csvText.concat(
      separator,
      String(totalLate.toFixed(DECIMAL_PRECISION)),
      separator,
      String(totalPaid.toFixed(DECIMAL_PRECISION)),
      separator,
      String(totalExpected.toFixed(DECIMAL_PRECISION)),
      newLine
    );
  });

  const blob = new Blob([csvText], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");

  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "latePayments.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

type Props = {
  onSuccess: (successMessage?: string) => void;
  onError: (errorMessage: string | undefined, errorCode?: string) => void;
};

const AdminGetLatePaymentView = ({ onSuccess, onError }: Props) => {
  const passcodeState = useFieldState(Field.Passcode);
  const [processing, setProcessing] = React.useState<boolean>(false);
  const [fileCompleted, setFileCompleted] = React.useState<boolean>(false);

  const { error, data } = useQuery(GET_LATE_PAYMENTS, {
    variables: {
      passcode: String(passcodeState.value),
    },
    fetchPolicy: "no-cache",
    skip: !processing,
  });

  useEffect(() => {
    if (error) {
      if (error instanceof Error) {
        onError(
          error.message,
          hasErrorCode(error, "NOT_ADMIN") ? "NOT_ADMIN" : undefined
        );
      }

      setProcessing(false);
    }
  }, [error, onError]);

  useEffect(() => {
    if (data?.adminGetLatePaymentView) {
      setProcessing(false);
      convertToCSV(data?.adminGetLatePaymentView.policies);
      setFileCompleted(true);
    }
  }, [data]);

  const handleSubmit = () => {
    setProcessing(true);
    onSuccess(undefined);
    onError(undefined);
  };

  return (
    <div className={styles.Content}>
      <H3>Late Payment CSV File</H3>

      {!fileCompleted && (
        <>
          <TextInput
            type="text"
            label="Passcode"
            placeholder="Passcode"
            field={Field.Passcode}
          />

          <Button disabled={processing} onClick={handleSubmit}>
            {processing ? "Fetching..." : "Fetch CSV File"}
          </Button>
        </>
      )}
    </div>
  );
};

export default AdminGetLatePaymentView;
